<template>
    <Carousel v-if="checkType(bloc.type, 'slider_text')">
        <Slide v-for="slide in bloc.slides" :key="slide.type">
            <div class="carousel__item d-flex flex-column py-3 px-1">
                <div v-for="content in slide.slide" :key="content.type">
                    <TexteComponents :bloc="content" :zone="content.type" v-if="checkType(content.type, 'text')"/>
                    <TitreComponents :bloc="content" v-if="checkType(content.type, 'title_h1')"/>
                </div>
            </div>
        </Slide>

        <template #addons>
            <Pagination />
        </template>
    </Carousel>

    <Carousel v-if="checkType(bloc.type, 'slider_pdf')" :items-to-show="number(pdfsLenght(bloc.pdfs))" class="carousel__pdfs"
              :class="'carousel__pdfs--wrap'+wrapAround(pdfsLenght(bloc.pdfs))">
        <Slide v-for="(pdf, index) in bloc.pdfs" :key="index">
            <a :href="pdf.link" target="_blank" class="carousel__pdfs--link">
                <img :src="getImgUrl(pdf.image)" :alt="pdf.image"/>
                <div class="mt-2">{{pdf.text}}</div>
            </a>

        </Slide>

        <template #addons>
            <Pagination v-if="pdfsLenght(bloc.pdfs) > 2"/>
        </template>
    </Carousel>
</template>

<script>
    import { defineComponent } from 'vue'
    import { Carousel, Pagination, Slide } from 'vue3-carousel'

    import 'vue3-carousel/dist/carousel.css'

    export default defineComponent({
        props: ['bloc'],
        components: {
            Carousel,
            Slide,
            Pagination
        },
        methods: {
            checkType(type, check) {
                if(check === type) {
                    return true;
                }
                return false;
            },
            getImgUrl(img) {
                return require('../assets/img/'+img)
            },
            pdfsLenght(array) {
                if(array.length > 2) {
                    return 2.5;
                }
                return array.length;
            },
            wrapAround(nb) {
                if(nb > 1) {
                    return true;
                }
                return false;
            },
            number(nb) {
                if(nb >= 2) {
                    return 2;
                }
                return 1;
            }
        }
    })
</script>

<style lang="scss">
    .carousel__item {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .item__texte, .item__titre {
            color: $c-white;
        }
    }

    .carousel__pagination {
        padding: 0;

        &-button {
            width: 20px;
            height: 20px;
            border: 1px solid white;
            border-radius: 50%;
            margin: 0 6px;

            &:after {
                display: none;
            }

            &--active {
                background-color: $c-white;
            }
        }
    }

    .carousel__pdfs {
        margin: 0 -50px;

        &--link {
            margin: 0 15px;
        }

        &--wrapfalse {
            .carousel__slide {
                max-width: 220px;
                margin: 0 auto;
            }
        }

        .carousel__pagination {
            margin-top: 30px;
        }
    }

</style>
