<template>
  <ul class="item__list">
    <li v-for="item in bloc.items" :key="item.type" class="item__list--items">
      <img src="../assets/img/arrow-right.png" width="15" alt="arrow right" class="item__list--items--arrow"/>

      <TexteComponents :bloc="item" v-if="checkType(item.type, 'text')"/>
    </li>
  </ul>
</template>

<script>

export default {
  props: ['bloc'],
  methods : {
      checkType(type, check) {
          if(check === type) {
              return true;
          }
          return false;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.item__list  {
  list-style: none;
  &--items {
    position: relative;
    &--arrow {
      position: absolute;
      left: -30px;
      top: 20px;
    }
  }
}
</style>
