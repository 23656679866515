<template>
  <div class="item__chapter" :id="bloc.id">
    <figure>
      <div class="item__chapter--texte ff-anton">
        <div class="item__chapter--texte--titre f-normal text-white text-uppercase fw-bold text-center ff-anton">{{bloc.text}}</div>
        <div class="item__chapter--texte--numero f-normal text-white fw-bold d-flex justify-content-center align-items-center position-relative mx-auto my-0">
          <span>
            {{bloc.number}}
          </span>
        </div>
      </div>
      <img src="../assets/img/chapter.png" alt="chapter" width="152" class="item__chapter--img"/>
    </figure>
  </div>
</template>

<script>
export default {
  props: ['bloc']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .item__chapter {
    //opacity: 0;
    //transform: scale(0);

    figure {
      position: relative;
      display: inline-flex
    }

    &--texte {
      position: absolute;
      top: 7px;
      left: 50%;
      transform: translateX(-50%);


      &--titre {
        margin-bottom: 5px;
        letter-spacing: 0.5px;
      }

      &--numero {
        background-color: $c-blue-dark;
        width: 44px;
        height: 44px;
        border-radius: 50%;

        & > span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

</style>
