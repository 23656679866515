import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { MotionPlugin } from '@vueuse/motion'

import 'bootstrap/scss/bootstrap.scss'
import './assets/scss/global.scss';

import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';

import TexteComponents from "./components/TexteComponents.vue";
import ImageComponents from "./components/ImageComponents.vue";
import ImagePolypsComponents from "./components/ImagePolypsComponents";
import ChapterComponents from "./components/ChapterComponents";
import TitreComponents from "./components/TitreComponents";
import ButtonComponents from "./components/ButtonComponents"
import SpacerComponents from "./components/SpacerComponents"
import CarouselComponents from "./components/CarouselComponents"
import CapsuleComponents from "./components/CapsuleComponents"
import ModalComponents from "./components/ModalComponents";
import ListeComponents from "./components/ListeComponents";
import ChiffreComponents from "./components/ChiffreComponents";
import VideoComponents from "./components/VideoComponents";
import LiensComponents from "./components/LiensComponents";
import MasqueComponents from "./components/MasqueComponents";

const app = createApp(App);


app.component("TexteComponents", TexteComponents);
app.component("ImageComponents", ImageComponents);
app.component("ImagePolypsComponents", ImagePolypsComponents);
app.component("ChapterComponents", ChapterComponents);
app.component("TitreComponents", TitreComponents);
app.component("ButtonComponents", ButtonComponents);
app.component("SpacerComponents", SpacerComponents);
app.component("CarouselComponents", CarouselComponents);
app.component("CapsuleComponents", CapsuleComponents);
app.component("ModalComponents", ModalComponents);
app.component("ListeComponents", ListeComponents);
app.component("ChiffreComponents", ChiffreComponents);
app.component("VideoComponents", VideoComponents);
app.component("LiensComponents", LiensComponents);
app.component("MasqueComponents", MasqueComponents);

app.component('scroll-parallax', ScrollParallax);

app.use(router);
app.use(MotionPlugin);

app.mount('#app');


