import $ from 'jquery'

const global = {

    init() {

        console.log("%cGenesii ❤", "color: #9ed6a8; padding: 5px 0px 1px; border-bottom:2px solid #9ed6a8;");

        window.addEventListener('resize', this.appHeight);
        this.appHeight();

        $('.scroll__up').off('click').on('click', function () {
            window.scrollTo({top: 0, behavior: 'smooth'})
        });

        $('.scroll__down .scroll__down--img').off('click').on('click', function () {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#start__diving").offset().top
            }, 100);
        });

        $("img[alt='btn-start-diving.png']").off('click').on('click', function () {
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#start__diving").offset().top
            }, 100);
        })

        let instance = this;
        $('a[href="#modal"]').off('click').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('body').css({'overflow': 'hidden'});

            if($(this).data('target')) {
                let target = $(this).data('target');
                $(target).addClass('active');
                $(target).find('.custom__modal--inner').addClass('active');
            }

            $('.custom__modal--close').off('click').on('click', function () {
                let target = $($(this).parents('.custom__modal')[0]);
                instance.closeModal(target);
            })

            $('.custom__modal--menu-item a').off('click').on('click', function (e) {
                e.stopPropagation();
                e.preventDefault();
                let target = $(this).attr('href');

                let targetModal = $($(this).parents('.custom__modal')[0]);
                instance.closeModal(targetModal);

                if($(target).length > 0) {
                    $([document.documentElement, document.body]).animate({
                        scrollTop: $(target).offset().top
                    }, 100);
                }

            })

        });

        $('.container--fixed').each(function () {
            $(this).find('[data-animation="fixed"]').parent().css({'height' : $(this).find('[data-animation="fixed"]').outerHeight()+'px'});
        });

        let heightContainer = $('.diving_mask--container').outerHeight();
        let maskPosition = $('#diving_mask').offset().top;

        setMaskPosition();
        $(window).on("load", function(){
            setMaskPosition();
        });

        function setMaskPosition() {
            let delay = 0;
            let interval = setInterval(function () {
                delay+=100;
                maskPosition = $('#diving_mask').offset().top;
                $('#diving_mask').parent().css({'height': $('#diving_mask').outerHeight()+'px'});
                heightContainer = $('.diving_mask--container').outerHeight();

                if(delay > 1000) {
                    clearInterval(interval);
                }
            }, delay)
        }

        var decalageTop = 200;
        $('.container--custom').removeClass('blured');

        $(window).on('scroll', function () {

            //anim masque
            let endPosition = maskPosition + heightContainer;
            let middleHeight = $(window).height() / 2;

            let currentScrollTop = $(this).scrollTop() + middleHeight;

            let scrollMask = currentScrollTop - maskPosition;

            if(currentScrollTop > maskPosition) {
                $('#diving_mask').removeClass('finish');
                $('#diving_mask').addClass('fixed');
                $('#diving_mask').css({'top' : middleHeight-20+ 'px', 'transform': 'translateX(-50%) scale(calc(1 + '+ 20 * (scrollMask/heightContainer)+'))'})

                if(currentScrollTop > endPosition + 500) {
                    $('#diving_mask').addClass('finish');
                    $('.container--custom').removeClass('blured')

                } else {
                    $('.container--custom').each(function () {
                        if($('#diving_mask').offset().top < ($(this).offset().top + ($(this).outerHeight()/2))) {
                            $(this).addClass('blured')
                        } else {
                            $(this).removeClass('blured')
                        }

                        if($(this).parents('.diving_mask--space').length > 0) {
                            if($(this).offset().top < ($(window).scrollTop() + ($(window).height()/2))) {
                                $(this).css({'opacity' : 1});
                            } else {
                                $(this).css({'opacity' : 0});
                            }
                        }
                    });

                    if(($(window).scrollTop() + 100) > $('.diving_mask--space--ref').offset().top) {
                        $('.container--custom').removeClass('blured')
                        $('#diving_mask').addClass('finish');
                        $('.diving_mask--space .container--custom').removeClass('blured')
                    } else {
                        $('.diving_mask--space .container--custom').addClass('blured')
                    }
                }
            } else {
                $('#diving_mask').removeClass('fixed');
                $('#diving_mask').attr('style', '')
                $('.diving_mask--container > *').removeClass('opacity-0')
                $('.diving_mask--space .container--custom').removeClass('blured')
                $('.container--custom').removeClass('blured');
            }

            //anim zone fixed
            $('.container--fixed').each(function () {
                if($(window).scrollTop() > $(this).offset().top && $(window).scrollTop() < ($(this).offset().top + ($(this).outerHeight() - (decalageTop)))) {
                    $('[data-animation="fixed"]').removeClass('fixed');

                    $(this).find('[data-animation="fixed"]').addClass('fixed');
                    $(this).find('[data-animation="fixed"]').removeClass('opacity-0');
                    $(this).find('[data-animation="fixed"]').parent().css({'height' : $(this).find('[data-animation="fixed"]').outerHeight()+'px', 'backface-visibility' : 'hidden'})

                    let container = $(this);
                    container.find('.bloc--text__image').each(function () {

                        var extraSpace = 0;
                        if($(window).width() >= 768 && ($(window).height() / 4) > 200) {
                            decalageTop = $(window).height() / 4;
                            extraSpace = 150;
                        } else {
                            decalageTop = 175;
                            extraSpace = 0;
                        }

                        if(($(window).scrollTop() + (decalageTop*2)) > ($(this).offset().top - extraSpace) && ($(window).scrollTop() + (decalageTop*2)) < ($(this).offset().top + $(this).outerHeight() + extraSpace)) {
                            $(this).find('[data-animation="fixed2"]').addClass('fixed');
                            $(this).find('[data-animation="fixed2"]').parent().css({'height' : $(this).find('[data-animation="fixed2"]').outerHeight()+'px', 'backface-visibility' : 'hidden'});
                            $(this).find('[data-animation="fixed2"]').css({'top' : decalageTop+'px', 'left' : $(this).offset().left+'px'});
                            //container.find('[data-animation="fixed2"]').removeClass('opacity');

                            if(!$(this).find('[data-animation="fixed2"]').hasClass('opacity')) {
                                $(this).find('[data-animation="fixed2"]').addClass('opacity');

                                $(this).find('.item__image.opacity-animation img').css({'opacity': 1});
                            }

                        } else {
                            $(this).find('[data-animation="fixed2"]').removeClass('opacity');
                            $(this).find('.item__image.opacity-animation img').css({'opacity': 0});

                        }
                    })

                } else {

                    if($(window).scrollTop() < $(this).offset().top || $(window).scrollTop() > ($(this).offset().top + $(this).outerHeight())) {
                        $(this).find('[data-animation="fixed"]').removeClass('fixed');
                    } else {
                        $(this).find('[data-animation="fixed"]').addClass('opacity-0');
                    }

                    $(this).find('[data-animation="fixed2"]').removeClass('opacity');
                    $(this).find('.item__image.opacity-animation img').css({'opacity': 0});
                }
            });
        });


        if($('nav').length > 0 && $('.quick__access--mobile').length > 0) {
            $('nav').find('.quick__access .text-end').html($('.quick__access--mobile .text-end').text());
        } else {
            $('nav').find('.quick__access').addClass('d-none');
        }

        //link in sliders for prevent drag
        var start, end, diff;
        var clickTime = 200;

        var links = $('.carousel__pdfs .carousel__slide a');
        var interval;

        links.each(function () {
            $(this).off('mousedown').on('mousedown', function() {
                start = Date.now();

                interval = setInterval(function () {
                    end = Date.now();
                    diff = (end - start) + 1;

                    if (diff > clickTime) {
                        $('.carousel__pdfs .carousel__slide a').css({'pointer-events': 'none'});

                        $(window).off('mouseup').on('mouseup', function() {
                            setTimeout(function () {
                                $('.carousel__pdfs .carousel__slide a').css({'pointer-events': ''});
                            }, clickTime)

                            clearInterval(interval);
                        })
                    }
                }, 100);
            })
        })


    },

    appHeight() {
        var vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    isInModal(el) {
        if($(el).parents('.custom__modal').length > 0) {
            return true;
        }
        return false;
    },

    closeModal(target) {
        $('body').css({'overflow': ''});
        target.find('.custom__modal--inner').removeClass('active');

        setTimeout(function () {
            target.removeClass('active');
        }, 200)
    }
}

export default global