<template>
  <div class="item__chiffres">
    <div class="item__chiffres--item ff-anton d-flex align-items-end justify-content-center">
      {{bloc.number}}
      <span>{{bloc.value}}</span>
    </div>
    <h2 v-html="bloc.text" class="item__titre c-blue-dark f-titre-h2 ff-anton right mb-4"></h2>
  </div>
</template>

<script>
export default {
  props: ['bloc']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

  .item__chiffres {
    &--item {
      -webkit-text-stroke: 1px $c-blue;
      color: transparent;
      font-size: $fs-titre-chiffre;
      line-height: $fs-titre-chiffre;

      span {
        font-size: $fs-titre-chiffre-little;
        line-height: $fs-titre-chiffre-little;
      }
    }

    .item__titre
    {
      margin-top: -35px;
      &.right {
        text-align: left;
      }
    }
  }

</style>
