<template>
  <div class="diving_mask diving_mask--container">
    <figure>
      <img src="../assets/img/masque.png" alt="diving_masque" width="347" id="diving_mask" class="diving_mask--item"/>
    </figure>

    <div class="diving_mask--space">
      <div style="height: 10vh"></div>
      <div class="diving_mask--space--ref">
        <div v-for="bloc in bloc.content" :key="bloc.type">
          <div class="container--custom blured">
            <ImageComponents :bloc="bloc" v-if="checkType(bloc.type, 'image')"/>
            <TexteComponents :bloc="bloc" :zone="zone.type" v-if="checkType(bloc.type, 'text')"/>
            <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h1')"/>
            <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_h2')"/>
            <TitreComponents :bloc="bloc" v-if="checkType(bloc.type, 'title_full')"/>
            <SpacerComponents :bloc="bloc" v-if="checkType(bloc.type, 'spacer')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['bloc'],
  methods: {
      checkType(type, check) {
          if(check === type) {
              return true;
          }
          return false;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

.diving_mask {

  #diving_mask {
    position: relative;
    z-index: 99999;
  }

  &--space {
    .container--custom {
      opacity: 0;
      transition: opacity .35s ease, filter .35s ease;

      &.blured {
        filter: blur(5px);
      }
    }
  }
}

  #diving_mask {
    &.fixed {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      opacity: 1;
      transition: opacity .2s ease;

      &.finish {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

.container--custom {
  transition: filter .35s ease;

  &.blured {
    filter: blur(5px);
  }
}

</style>
